.toggle {
	position: relative;
	display: flex;
	align-items: center;
	appearance: none;
	padding: 7px 17px;
	cursor: pointer;
}

.toggleContainer {
	min-height: 32px;
	padding-bottom: 10px;
}

.toggleTracker {
	height: 14px;
	background-color: #e0e0e0;
	border-radius: 12px;
}

.toggleLabel {
	padding-bottom: 0px !important;
	padding-left: 5px;
	margin-bottom: 0px !important;
	cursor: pointer;
}

.toggleTracker:after {
	content: "";
	width: 20px;
	height: 20px;
	position: absolute;
	background-color: var(--color-white);
	border-radius: 50%;
	left: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	box-shadow: 0 0 2px 2px #00000010;
	-webkit-transform: translateX(-2px);
	-ms-transform: translateX(-2px);
	transform: translateX(-2px);
}

.checked.toggleTracker {
	background-color: #c8bfff;
}

.checked.toggleTracker:after {
	background-color: var(--color-primary-500);
	-webkit-transform: translateX(15px);
	-ms-transform: translateX(15px);
	transform: translateX(15px);
}
