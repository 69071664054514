.controlBtn {
	color: var(--color-black);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;
	transition: 0.2s;
}

.controlBtn:hover {
	opacity: 0.7;
}

.controlBtn:active {
	opacity: 0.5;
}

.overlay {
	left: 1.8em!important;
}
