@import "colors";

:root {
	--elements-background-active: var(--color-gray-100);
	--elements-background-hover: var(--color-primary-50);
	--elements-background-disabled: var(--color-gray-50);
	--elements-background-selection: var(--color-primary-500);
	--elements-background-selection-hover: var(--color-primary-600);
	--elements-background-selection-disabled: var(--color-gray-200);
	--elements-background-inverse-active: var(--color-white);
	--elements-background-inverse-disabled: var(--color-white);
	--elements-background-negative: var(--color-error-500);
	--elements-background-negative-secondary: var(--color-error-100);
	--elements-background-success: var(--color-success-500);
	--elements-background-success-secondary: var(--color-success-100);
	--elements-background-warning: var(--color-warning-500);
	--elements-background-warning-secondary: var(--color-warning-100);

	--uiblock-border: var(--color-gray-200);

	--ad-body-bg: var(--color-gray-25);

	--menu-bg-color: var(--color-gray-25);
	--menu-text-color: var(--color-gray-600);
	--menu-item-hover-color: var(--color-primary-500);
	--menu-item-hover-bg: rgba(100, 77, 237, 0.08);

	--form-label-text-color: var(--color-gray-600);

	--form-control-bg-color: var(--color-white);
	--form-control-border-color: rgba(var(--color-black-rgb), 0.2);
	--form-control-text-color: var(--color-black);
	--form-control-hover-border-color: var(--color-primary-500);
	--form-control-placeholder-color: var(--color-gray-600);
	--form-control-active-border-color: var(--color-primary-500);
	--form-control-active-shadow-color: var(--color-primary-100);

	--form-select-themed-menu-bg-color: var(--form-control-bg-color);

	--form-check-bg-color: var(--color-primary-500);
	--form-check-active-shadow-color: rgba(var(--color-primary-100-rgb), 0.2);

	--ad-btn-color: var(--color-black);
	--ad-btn-bg: var(--color-white);
	--ad-btn-border-color: var(--form-control-border-color);
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-gray-100);
	--ad-btn-hover-border-color: var(--color-gray-600);
	--ad-btn-active-color: var(--ad-btn-color);
	--ad-btn-active-bg: var(--color-gray-200);
	--ad-btn-active-border-color: var(--ad-btn-hover-border-color);
	--ad-btn-disabled-opacity: 0.65;

	--error-color: var(--color-error-600);

	--ad-icon-color: var(--color-gray-600);

	--ad-datepicker-bg: var(--color-white);
	--ad-datepicker-border-color: rgba(var(--color-black-rgb), 0.2);
	--ad-datepicker-day-color: var(--color-gray-700);
	--ad-datepicker-day-outside-color: var(--color-gray-500);
	--ad-datepicker-day-hover-color: var(--color-primary-500);
	--ad-datepicker-day-hover-bg: var(--color-primary-50);
	--ad-datepicker-day-selected-color: var(--color-white);
	--ad-datepicker-day-selected-bg: var(--color-primary-500);
}

.dark {
	--ad-body-bg: var(--color-gray-900);

	--menu-bg-color: var(--color-gray-900);

	--form-label-text-color: var(--color-white);

	--form-control-bg-color: rgba(var(--color-gray-600-rgb), 0.24);
	--form-control-border-color: rgba(var(--color-gray-600-rgb), 0.24);
	--form-control-text-color: var(--color-white);
	--form-control-active-shadow-color: rgba(var(--color-primary-100-rgb), 0.2);

	--form-select-themed-menu-bg-color: var(--color-gray-800);
}

