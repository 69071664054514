.container {
	background: var(--color-white);
	padding: 10px 15px;
	border-radius: 10px;
	text-align: left;
	width: 100%;
}

.title {
	color: var(--color-gray-700);
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 5px;
}

.value {
	color: var(--color-black);
	font-size: 40px;
	line-height: 46px;
}

.change {
	color: var(--color-black);
	font-size: 16px;
	line-height: 18px;
	margin-top: 5px;
}

.change_positive {
	color: var(--color-success-600);
}

.change_negative {
	color: var(--color-error-600);
}

.list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
}

@media (max-width: 768px) {
	.list {
		grid-template-columns: 1fr;
	}
}