.container {
	width: 100%;
	height: 100%;
	padding-left: 20px;
}

.content {
	margin-top: 20px;
}

.saverControl {
	left: 0;
	position: absolute;
	width: calc(100% + 25px);
	bottom: -20px;
}

@media (max-width: 768px) {
	.saverControl {
		position: fixed;
		bottom: 0;
	}
}
