$offsets: 5px, 7px, 10px, 14px, 15px;

.transition-ease,
.transition-ease:hover,
.transition-ease path {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

@mixin transition-ease {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;

	&:hover {
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}

	path: {
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
}

button,
input,
.constructor-content-item,
.app-form div {
	@include transition-ease();
}

.app-form {
	max-width: 291px;
	margin: auto;

	&_invisible {
		display: none;
	}
}

.arrow {
	cursor: default;
}

.pointer {
	cursor: pointer;
}

.text-cropped {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-top: 13px;
	padding-bottom: 13px;
}

.text-center {
	text-align: center;
}

.hide {
	display: none;
}

.show {
	display: block;
}

@each $offset in $offsets {
	.padding {
		&-#{$offset} {
			padding: $offset;
		}

		&-vertical-#{$offset} {
			padding-top: $offset;
			padding-bottom: $offset;
		}

		&-horizontal-#{$offset} {
			padding-left: $offset;
			padding-right: $offset;
		}

		&-top-#{$offset} {
			padding-top: $offset;
		}

		&-left-#{$offset} {
			padding-left: $offset;
		}

		&-right-#{$offset} {
			padding-right: $offset;
		}

		&-bottom-#{$offset} {
			padding-bottom: $offset;
		}
	}

	.margin {
		&-#{$offset} {
			margin: $offset;
		}

		&-vertical-#{$offset} {
			margin-top: $offset;
			margin-bottom: $offset;
		}

		&-horizontal-#{$offset} {
			margin-left: $offset;
			margin-right: $offset;
		}

		&-top-#{$offset} {
			margin-top: $offset;
		}

		&-left-#{$offset} {
			margin-left: $offset;
		}

		&-right-#{$offset} {
			margin-right: $offset;
		}

		&-bottom-#{$offset} {
			margin-bottom: $offset;
		}
	}
}

.fs-16 {
	font-size: 16px;
	line-height: 18px;
}

.fs-14 {
	font-size: 14px;
	line-height: 16px;
}

.circle-border {
	border-radius: 50%;
}
