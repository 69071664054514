@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-black-rgb: #{HexToRGB(#000000)};

  --color-gray-25: #F9F9FC;
  --color-gray-50: #f9faff;
  --color-gray-100: #F3F3F8;
  --color-gray-100-rgb: #{HexToRGB(#F3F3F8)};
  --color-gray-200: #DADAE6;
  --color-gray-300: #C7C8D9;
  --color-gray-400: #ABACC0;
  --color-gray-500: #a3a3b5;
  --color-gray-600: #7A7A8B;
  --color-gray-600-rgb: #{HexToRGB(#7A7A8B)};
  --color-gray-700: #656676;
  --color-gray-800: #464656;
  --color-gray-900: #242533;

  --color-primary-50: #F4F1FD;
  --color-primary-100: #ECE6FC;
  --color-primary-100-rgb: #{HexToRGB(#ECE6FC)};
  --color-primary-500: #644DED;
  --color-primary-600: #4930EF;
  --color-primary-700: #3B27C1;

  --color-warning-100: #FEF0C7;
  --color-warning-500: #F79009;

  --color-error-50: #FEF3F2;
  --color-error-100: #FEE4E2;
  --color-error-500: #F04438;
  --color-error-600: #D92D20;
  --color-error-700: #B42318;
  --color-error-800: #912018;

  --color-success-50: #ECFDF3;
  --color-success-100: #D1FADF;
  --color-success-500: #12B76A;
  --color-success-600: #039855;
  --color-success-700: #02633A;
  --color-success-800: #03462A;
}
