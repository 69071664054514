.container {
	display: flex;
	justify-content: space-between;
}

.header {
	color: #101827;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
}

.breadcrumbs {
	margin-bottom: 2px;
}
.breadcrumb_link {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}
