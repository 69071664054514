.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.navContainer {
	padding: 25px 10px 25px 0;
	margin-left: -25px;
	margin-top: -25px;
	width: 240px;
	height: calc(100% + 50px);
	background-color: var(--color-white);
	border-right: 1px solid #ebebf1;
}

.contentContainer {
	width: 100%;
	height: 100%;
	position: relative;
}

@media (max-width: 768px) {
	.navContainer {
		margin-top: 0;
		background: var(--menu-bg-color);
		padding-top: 0;
		border: none;
	}
}
