.container {
	display: flex;
	align-items: center;
}

.spinnerContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
}

.loadingBtn {
	position: relative;
}

.active {
	color: transparent !important;
}

.successText {
	visibility: hidden;
	opacity: 0;
	transition: 0.2s;
	margin-left: 10px;
	font-size: 14px;
	font-weight: 400;
	color: var(--color-success-600);
}

.successTextActive {
	visibility: visible;
	opacity: 1;
}
