.control {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 18px;
}

.avatar {
	margin-right: 10px;
}

.name {
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	margin-right: 10px;
}
