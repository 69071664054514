input {
	padding: 16px 17px;
}

.app-form {
	max-width: 100%;
	margin: 0px;
}

.form-label {
	margin-bottom: 4px;
	font-weight: 700;
	font-size: 14px;
	color: var(--form-label-text-color);
}

.form-control {
	border-radius: 8px;
	padding: 8px 11px;
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 10px;
	color: var(--form-control-text-color);
	caret-color: var(--form-control-text-color);

	&:not(textarea) {
		height: 34px;
	}

	&.form-control-lg {
		line-height: 24px;

		&:not(textarea) {
			height: 46px;
		}
	}
}

.form-control,
.form-control::placeholder {
	background-clip: unset;
	line-height: 24px;
}

.form-control::placeholder {
	color: var(--form-control-placeholder-color);
	-webkit-text-fill-color: var(--form-control-placeholder-color);
}

textarea {
	font-size: 14px !important;
	line-height: 16px !important;
	padding: 5px 12px !important;
}

.form-select-themed {
	min-height: 34px !important;
	border-radius: 8px !important;
}

.form-select-container-themed {
	font-size: 16px !important;
	line-height: 18px !important;
	padding: 7px 9px !important;
}

.form-select-themed-input {
	font-size: 16px !important;
	line-height: 18px !important;
	padding: 0 !important;
	margin: 0 !important;
}

.form-select-themed-menu {
	background-color: var(--form-select-themed-menu-bg-color) !important;
	font-size: 16px !important;
	line-height: 18px !important;
	border-radius: 8px !important;
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.form-select-themed-menu-option {
	background-color: transparent !important;
	border-radius: 8px !important;
	margin-bottom: 2px;
}

.form-select-themed-menu-option_focused {
	background-color: var(--form-control-active-shadow-color) !important;
}

.form-select-themed-menu-option_selected {
	background-color: var(--form-control-hover-border-color) !important;
}

.form-select-themed-singleValue {
	font-size: 16px !important;
	line-height: 18px !important;
}

.form-select-themed-indicator {
	padding: 6px 8px !important;
}

.form-control:hover,
.form-select-themed:hover {
	border-color: var(--form-control-hover-border-color) !important;
}

.form-control,
.form-select-themed,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill,
.form-select-themed:-webkit-autofill {
	background-color: var(--form-control-bg-color) !important;
	border: 1px solid var(--form-control-border-color) !important;
	margin-bottom: 10px;

	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: var(--form-control-text-color) !important;
}

.form-control:focus,
.form-select-themed:focus,
.form-select-themed_focused,
.form-control:-webkit-autofill:focus,
.form-select-themed:target,
.form-control:target {
	box-shadow: 0 0 0 3px var(--form-control-active-shadow-color) !important;
	border: 1px solid var(--form-control-active-border-color) !important;

	-webkit-box-shadow: 0 0 0 3px var(--form-control-active-shadow-color) !important;
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: var(--form-control-text-color) !important;
}

.form-check {
	padding-left: 0px;
}

.form-check .form-check-input {
	width: 16px;
	height: 16px;
	padding: 0px;
	margin-top: 0;
	margin-left: 0px;
	margin-right: 8px;
}

.form-check-input:checked {
	background-color: var(--form-check-bg-color);
	border-color: var(--form-check-bg-color);
}

.form-check-input:focus {
	box-shadow: 0 0 0 0.25rem var(--form-check-active-shadow-color);
}

.has-error .form-control,
.has-error .form-select-themed {
	border-color: var(--error-color) !important;
}

.has-error .form-select-themed:focus,
.has-error .form-control:focus {
	box-shadow: 0 0 0 3px var(--color-error-100) !important;
}

.has-error .form-control::placeholder,
.has-error .form-select-themed::placeholder {
	color: var(--error-color) !important;
}
