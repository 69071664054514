.mainContainer {
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}

.contentContainer {
	height: 100%;
	margin-bottom: 100px;
}

.controlContainer {
	position: fixed;
	bottom: 0;
	left: 220px;
	padding: 10px 32px;
	width: 100%;
	background-color: var(--color-white);
	border-top: 1px solid var(--color-gray-100);
}

.form {
	margin: 0 !important;
}

.titleContainer h1 {
	color: #101827;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	padding: 24px 0;
}
