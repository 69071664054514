.container {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 100%;
}

.tableContainer {
	width: 100%;
	max-height: 100%;
	overflow-y: auto;
	padding-right: 4px;
}

.counter {
	margin-bottom: 10px;
}

.paginationContainer {
	margin-top: auto;
}

.nameRow {
	display: flex;
	align-items: center;
}

.nameRow div {
	margin-left: 5px;
}

.content {
	margin-top: 20px;
	height: 100%;
}
