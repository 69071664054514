button {
	padding: 15px 8px;
}

.btn {
	font-size: 14px;
	line-height: 16px;
	padding: 7px 15px;
	background-color: var(--ad-btn-bg) !important;
	color: var(--ad-btn-color) !important;
	border: 1px solid var(--ad-btn-border-color) !important;

	--ad-icon-color: var(--ad-btn-color);

	& svg {
		margin-right: 4px;
	}
	
	&:hover,
	&:focus {
		color: var(--ad-btn-hover-color) !important;
		background-color: var(--ad-btn-hover-bg);
		border: 1px solid var(--ad-btn-hover-border-color) !important;
		--ad-icon-color: var(--ad-btn-hover-color);
	}

	&:active {
		color: var(--ad-btn-active-color) !important;
		background-color: var(--ad-btn-active-bg) !important;
		border: 1px solid var(--ad-btn-active-border-color) !important;
		--ad-icon-color: var(--ad-btn-active-color);
	}

	&_onlyicon {
		padding: 5px 5px;
	}

	&_onlyicon svg {
		margin-right: 0px;
	}

	&:disabled {
		opacity: var(--ad-btn-disabled-opacity);
		color: var(--ad-btn-hover-color) !important;
		background-color: var(--ad-btn-hover-bg);
		border: 1px solid var(--ad-btn-hover-border-color) !important;
	}

	// &_ghost {
	// 	border: transparent;
	// }

	// &_ghost:hover,
	// &_ghost:focus
	// {
	// 	border: 1px solid var(--color-gray-700);
	// }
}

.btn-primary {
	--ad-btn-color: var(--color-white);
	--ad-btn-bg: var(--color-primary-500);
	--ad-btn-border-color: var(--color-primary-500);
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-primary-600);
	--ad-btn-hover-border-color: var(--color-primary-600);
	--ad-btn-active-color: var(--ad-btn-color);
	--ad-btn-active-bg: var(--color-primary-700);
	--ad-btn-active-border-color: var(--color-primary-700);
}

.btn-primary.btn-outline {
	--ad-btn-color: var(--color-primary-700);
	--ad-btn-bg: transparent;
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-primary-50);
	--ad-btn-hover-border-color: var(--color-primary-500);
	--ad-btn-active-color: var(--color-white);
	--ad-btn-active-bg: var(--color-primary-500);
	--ad-btn-active-border-color: var(--color-primary-500);
}

.btn-success {
	--ad-btn-color: var(--color-white);
	--ad-btn-bg: var(--color-success-600);
	--ad-btn-border-color: var(--color-success-600);
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-success-700);
	--ad-btn-hover-border-color: var(--color-success-700);
	--ad-btn-active-color: var(--ad-btn-color);
	--ad-btn-active-bg: var(--color-success-800);
	--ad-btn-active-border-color: var(--color-success-800);
}

.btn-success.btn-outline {
	--ad-btn-color: var(--color-success-700);
	--ad-btn-bg: transparent;
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-success-50);
	--ad-btn-hover-border-color: var(--color-success-500);
	--ad-btn-active-color: var(--color-white);
	--ad-btn-active-bg: var(--color-success-600);
	--ad-btn-active-border-color: var(--color-success-600);
}

.btn-secondary {
	--ad-btn-color: var(--color-white);
	--ad-btn-bg: var(--color-gray-500);
	--ad-btn-border-color: var(--color-gray-500);
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-gray-600);
	--ad-btn-hover-border-color: var(--color-gray-600);
	--ad-btn-active-color: var(--ad-btn-color);
	--ad-btn-active-bg: var(--color-gray-700);
	--ad-btn-active-border-color: var(--color-gray-700);
}

.btn-secondary.btn-outline {
	--ad-btn-color: var(--color-black);
	--ad-btn-bg: transparent;
	--ad-btn-border-color: var(--form-control-border-color);
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-gray-50);
	--ad-btn-hover-border-color: var(--color-gray-600);
	--ad-btn-active-color: var(--color-white);
	--ad-btn-active-bg: var(--color-gray-500);
	--ad-btn-active-border-color: var(--color-gray-500);
}

.btn-check:checked + .btn-secondary.btn-outline {
	--ad-btn-bg: var(--color-gray-700);
	--ad-btn-border-color: var(--color-gray-700);
}

// .btn-secondary.btn-ghost {
	// --ad-btn-border-color: transparent;
	// --ad-btn-bg: transparent;
	// --ad-btn-active-bg: transparent;
	// --ad-btn-active-border-color: var(--color-gray-700);
// }

.btn-danger {
	--ad-btn-color: var(--color-white);
	--ad-btn-bg: var(--color-error-600);
	--ad-btn-border-color: var(--color-error-600);
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-error-700);
	--ad-btn-hover-border-color: var(--color-error-700);
	--ad-btn-active-color: var(--ad-btn-color);
	--ad-btn-active-bg: var(--color-error-800);
	--ad-btn-active-border-color: var(--color-error-800);
}

.btn-danger.btn-outline {
	--ad-btn-color: var(--color-error-700);
	--ad-btn-bg: transparent;
	--ad-btn-hover-color: var(--ad-btn-color);
	--ad-btn-hover-bg: var(--color-error-50);
	--ad-btn-hover-border-color: var(--color-error-500);
	--ad-btn-active-color: var(--color-white);
	--ad-btn-active-bg: var(--color-error-600);
	--ad-btn-active-border-color: var(--color-error-600);
}

.btn-ghost {
	@extend .btn-outline;
	--ad-btn-border-color: transparent!important;
}

.btn-lg {
	font-size: 18px;
	line-height: 20px;
	padding: 12px 18px;
	border-radius: 4px;
}

.btn-xs {
	font-size: 12px;
	line-height: 14px;
	border-radius: 4px;
	padding: 3px 7px;
}

.dark {
	.btn-secondary.btn-outline {
		--ad-btn-color: var(--color-white);
		--ad-btn-hover-bg: transparent;
		--ad-btn-active-bg: var(--color-gray-700);
		--ad-btn-active-border-color: var(--color-gray-700);
	}
}