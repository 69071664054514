.alert {
	color: var(--color-black);
	padding: 7px;
	display: flex;
	background-color: var(--elements-background-inverse-active);
	border: 1px solid var(--uiblock-border);
	border-radius: 8px;
}

.alert-heading {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
}

.alert__subheader {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: var(--color-gray-600);
}

.alert__subheader_main {
	color: var(--color-black);
}

.alert__content {
	padding: 5px 0px;
}
.alert-noicon .alert__content {
	padding: 1px 0px;
}

.alert__icon {
	padding: 3px 12px 3px 9px;
}

.alert-success {
	background-color: var(--elements-background-success-secondary);
	--ad-icon-color: var(--elements-background-success);
}

.alert__action {
	margin-left: auto !important;
}

.dark .alert {
	color: var(--color-gray-300);
	border: none;
	background-color: var(--form-control-bg-color);
}

.dark .alert__subheader_main {
	color: var(--color-gray-300);
}
