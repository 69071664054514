.react-datepicker-popper {
	z-index: 10;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
	fill: var(--ad-datepicker-bg);
	color: var(--ad-datepicker-bg);
	stroke: var(--ad-datepicker-border-color);
}
.react-datepicker {
	border-color: var(--ad-datepicker-border-color);
	border-radius: 8px;
}

.react-datepicker__header {
	background-color: var(--ad-datepicker-bg);
	border-bottom-color: var(--ad-datepicker-border-color);
	border-top-left-radius: 8px!important;
	border-top-right-radius: 8px!important;
}

.react-datepicker__day-name {
	width: 32px;
	height: 32px;
	line-height: 32px;
}

.react-datepicker__day {
	color: var(--ad-datepicker-day-color);
	border-radius: 16px;
	width: 32px;
	height: 32px;
	line-height: 32px;

	&:hover {
		color: var(--ad-datepicker-day-hover-color);
		background-color: var(--ad-datepicker-day-hover-bg);
		border-radius: 16px;
	}
}

.react-datepicker__day--outside-month {
	color: var(--ad-datepicker-day-outside-color);
}

.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
	color: var(--ad-datepicker-day-selected-color);
	background-color: var(--ad-datepicker-day-selected-bg);

	&:hover {
		color: var(--ad-datepicker-day-selected-color);
		background-color: var(--ad-datepicker-day-selected-bg);
	}
}