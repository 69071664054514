.wrapper {
	border-radius: 16px;
	border: 1px solid #e5e8f5;
	background: var(--color-white);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: var(--color-black);
	padding: 20px;
}

.title {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #f3f3f8;
	font-size: 16px;
}

.item {
	margin-top: 10px;
	font-size: 14px;
}

.itemTitle {
	color: var(--color-gray-700);
}
