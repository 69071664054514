.navbar-nav .nav-link.active {
	color: var(--menu-item-hover-color);
}

.menu {
	background: var(--menu-bg-color);
	border-right: 1px solid #ebebf1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100vh;
	font-size: 15px;
	line-height: 17px;
	position: fixed;
	width: 220px;
	z-index: 10;

	.navbar {
		flex-direction: column;

		&-nav {
			flex-direction: column;
			width: 100%;
		}
	}

	&__logo {
		display: flex;
		width: 100%;
		justify-content: space-around;
		margin-top: 10px;
	}

	a {
		text-decoration: none;
	}

	&__list {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px 15px;
		margin-top: 15px;

		&-item {
			width: 100%;
			border-radius: 8px;
			color: var(--menu-text-color);
			font-style: normal;
			display: block;
			font-size: 15px;
			margin-bottom: 5px;

			&:hover {
				background: var(--menu-item-hover-bg);
				border-radius: 8px;
				color: var(--menu-item-hover-color);
			}
		}

		.active {
			background: var(--menu-item-hover-bg);
			border-radius: 8px;
			color: var(--menu-item-hover-color);
		}
	}
}

.avatar {
	background-color: #f4e3b5;
	height: 32px;
	width: 32px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	color: var(--color-black);

	&__hovered {
		background-image: url("../../static/img/Avatar.png");
	}
}

.profile-data {
	margin-bottom: 10px;
}

#avatar-popover {
	.popover-arrow {
		display: none;
	}
}

.copy-to-clipboard {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 10px;
	gap: 10px;
	background: #f7f7f7;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	flex: none;
	order: 2;
	flex-grow: 0;

	&-code {
		font-size: 14px;
		font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
		line-height: 16px;
		color: var(--color-black);
		word-break: break-all;
		white-space: pre-wrap;
		word-wrap: break-word;
		display: block;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0;
}

.app-header-container {
	padding: 30px 20px;

	&__title {
		font-size: 35px;
		line-height: 40px;
		text-align: center;

		color: var(--color-black);
	}
}

.app-form__footer {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__additional {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	label,
	button,
	a {
		margin-bottom: 10px;
	}
}

.to-flex-right {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.agreement-block {
	color: var(--form-label-text-color);
	max-width: 324px;
	text-align: center;
}

.main-page {
	flex: 2;
	height: 100vh;
	display: flex;
	align-items: center;
	background-color: var(--color-white);
}

.main-section {
	flex: 1;
	padding: 22px 31px;
	height: 100vh;

	&__banner {
		background-color: #d9d9d9;
		background-image: url("../../static/img/main_login_bg.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&__content {
			flex: 1;
		}
	}
}

.profile-block {
	height: 20%;
}

.content-block {
	margin: auto;
	padding: 0 20px;
}

.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 5px solid #9747ff;
	animation: appLoader 2s linear infinite;
}
.loader::after {
	border-color: #9747ff;
	animation: appLoader 2s linear infinite, rotate 0.5s linear infinite reverse;
	inset: 6px;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes appLoader {
	0% {
		clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
	}
	25% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
	}
	50% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
	}
	75% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
	}
	100% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
	}
}

@-webkit-keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
	}
}

@keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

@media (max-width: 1200px) {
	.profile-block {
		height: 10%;
	}
	.content-block {
		width: 100%;
		padding: 0 4px;
	}
}

@media (max-width: 768px) {
	.menu {
		background: var(--menu-bg-color);
		display: flex;
		position: relative;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		font-size: 15px;
		line-height: 17px;
		width: 100%;
		height: 100%;
		padding: 10px;

		.navbar {
			flex-direction: row;

			&-nav {
				flex-direction: column;
				max-width: 150px;
				text-align: center;
			}
		}

		&__logo {
			justify-content: flex-start;
			margin-top: 0px;
		}

		&__list {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			padding: 0px 15px;
			margin-top: 15px;

			&-item {
				padding: 8px;
			}
		}
	}

	.main-page {
		flex-direction: column;
	}
	.main-section {
		&__banner {
			display: none !important;
		}
	}

	.profile-block {
		height: auto;
	}

	.content-block {
		width: 100%;
		padding: 20px;
	}

	.app-header-container {
		padding: 30px 0px;
	}

	.navbar-toggler-icon {
		width: 15px;
		height: 24px;
	}

	.navbar-toggler:focus {
		box-shadow: none;
	}
}
