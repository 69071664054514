.container {
    border-radius: 100px;
    background: #FF9314;
    display: flex;
    padding: 4px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}

.circle {
    border-radius: 100px;
}

.square {
    border-radius: 8px;
}

.sm {
	width: 20px;
	height: 20px;
	font-size: 8px;
}

.lg {
	width: 50px;
	height: 50px;
	font-size: 18px;
}
