.modal :global(.modal-header) {
    border-bottom: none;
}

.modal :global(.modal-footer) {
    border-top: none;
}

.formContainer {
    max-width: none!important;
    width: 100%;
}
