:root :global(.popover) {
    --bs-popover-border-width: 0;
}

.overlay :global(.popover-arrow) {
    display: none;
}

.contentContainer {
    padding: 9px 12px;
    border-radius: 4px!important;
    border: 1px solid var(--color-gray-200)!important;
}
