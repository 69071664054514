.container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.info {
	max-width: 70%;
	margin-top: 20px;
}
