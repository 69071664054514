.customBtn {
	background: none;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	display: flex;
	align-items: center;
	color: var(--color-primary-500);
	padding: 8px 0;
}
