@import "components/alert";
@import "components/badge";
@import "components/btn";
@import "components/forms";
@import "components/datepicker";

h1 {
	font-size: 35px;
	line-height: 40px;
	font-weight: bold;
}

h2 {
	font-size: 24px;
	line-height: 140%;
	font-weight: bold;
}

h3 {
	font-size: 18px;
	line-height: 20.7px;
	font-weight: 400;
}

h4 {
	font-size: 16px;
	line-height: 18.4px;
	font-weight: 400;
}

h5 {
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

h6 {
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
	padding: 0;
	margin: 0;
	margin-bottom: 8px;
}

.text-muted {
	color: var(--color-gray-700) !important;
}

.invalid-feedback {
	margin-top: 0;
	margin-bottom: 8px;
	font-size: 14px;
}

.navbar-nav .nav-link {
	padding: 8px 20px !important;
}

.bg-success {
	background-color: var(--elements-background-success) !important;
}

.copy-to-clipboard {
	background-color: var(--color-white);
}
