.pageWrapper {
	position: absolute;
	height: 100vh;
	left: 220px;
	display: flex;
	flex-direction: column;
	right: 0;
	padding: 25px;
}

@media (max-width: 768px) {
	.pageWrapper {
		position: relative;
		display: flex;
		padding: 25px;
		left: 0;
		min-height: 100vh;
	}
}