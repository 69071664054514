.text {
	color: #abacbe;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
}

.number {
	color: #484964;
}
