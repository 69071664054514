.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	color: var(--color-gray-500);
	text-align: center;
	padding: 10px 32px;
}

.container_noFullHeight {
	height: auto;
}
.title {
	font-size: 25px;
	line-height: 29px;
	margin-bottom: 10px;
}

.subtitle {
	font-size: 20px;
	line-height: 23px;
}

.container_withButton {}

.container_withButton .title {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 5px;
}

.container_withButton .subtitle {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
}

.button {
	margin-top: 15px;
}