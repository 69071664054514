.control {
    padding: 0 16px 16px 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.currentProjectInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.controlText {
    overflow: hidden;
    color: var(--text-primary);
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    max-width: 116px;
    white-space: nowrap;
}

.arrow {
    transition: 0.2s;
    display: flex;
    align-items: center;
}

.arrowActive {
    transform: rotate(180deg);
}

.projectIcon {
    width: 32px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background: rgba(100, 77, 237, 0.08);
    color: var(--color-primary-500);
}

.projectInfo {
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-primary-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.project {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 4px 6px;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s;
    cursor: pointer;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-primary);
}

.project > *:first-child {
    margin-right: 4px;
}

.project:hover {
    background-color: var(--elements-background-hover)
}

.currentProject {
    color: var(--color-primary-500);
}

.projectsContainer {
    padding: 6px;
}

.popoverBody {
    border: none!important;
    box-shadow: 0 0 1px 0 rgba(9, 11, 33, 0.20), 0 4px 4px 0 rgba(9, 11, 33, 0.08);
    padding: 4px;
    width: 200px;
}

.addProjectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-primary);
    cursor: pointer;
    padding: 6px 4px;
    border-radius: 6px;
}

.addProjectContainer:hover {
    background-color: var(--elements-background-hover)
}