*,
code {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	-webkit-font-smoothing: antialiased;
}

a {
	text-decoration: none;
	color: var(--color-primary-500);
}

body {
	background-color: var(--ad-body-bg);
}
