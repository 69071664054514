.container {
	width: 100%;
}

.container table {
	border-collapse: separate;
	border-spacing: 0;
}

.container table th {
	color: #484964;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	background-color: #f9f9fc;
	padding: 14px 20px;
}

.container table td {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: #484964;
	background-color: var(--color-white);
	padding: 10px 20px;
}

.container table td:first-child,
th:first-child {
	border-left: 1px solid #e5e8f5;
}

.container table th:first-child {
	border-top-left-radius: 16px;
}

.container table th:last-child {
	border-right: 1px solid #e5e8f5;
	border-top-right-radius: 16px;
}

.container table td,
th {
	border-top: 1px solid #e5e8f5;
}

.container table td:last-child {
	border-right: 1px solid #e5e8f5;
}

.container table tr:last-child td {
	border-bottom: 1px solid #e5e8f5;
}

.container table tr:last-child td:first-child {
	border-bottom-left-radius: 16px;
}

.container table tr:last-child td:last-child {
	border-bottom-right-radius: 16px;
}
