:global(.navbar-expand .navbar-nav .nav-link).link {
	padding-right: 8px !important;
	padding-left: 12px !important;
}

.link {
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	position: relative;
}

.link:global(.active):before {
	content: "";
	display: inline-block;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 4px;
	height: 20px;
	border-radius: 0 2px 2px 0;
	background: var(--color-primary-500, #644ded);
}
